import React from 'react'
// import { Hits } from 'react-instantsearch-dom'
import { Link } from 'gatsby'
// import { connectHits } from 'react-instantsearch-dom'
// import SoldListings from '../soldListings'

const slugify = require('slugify')

const Hits = ({ hits }) => {
  // console.log(hits)
  return (
    <div className="ais-Hits">
      <ul className="ais-Hits-list justify-content-center">
        {hits.map(({ node }) => (
          <li key={node.id} className="ais-Hits-item">
            <div className="mb-5">
              <div className="card bg-white">
                <div className="card-img">
                  <div
                    className="bg-card"
                    style={{
                      backgroundImage: `url(${node.Media &&
                        node.Media[0].asset.url})`,
                      backgroundPosition: 'center left',
                      backgroundSize: 'cover',
                      backgroundRepeat: 'no-repeat',
                    }}
                  />
                  <Link
                    to={`/${slugify(node.UnparsedAddress)}`}
                    className="btn-mgnfy"
                  >
                    <i className="fa fa-search" aria-hidden="true" />
                  </Link>
                </div>
                <div className="card-body">
                  <h4 className="card-title font-weight-normal">
                    <Link to={`/${slugify(node.UnparsedAddress)}`}>
                      {node.UnparsedAddress}
                    </Link>
                  </h4>
                  <ul className="list-unstyled d-flex justify-content-start align-items-center flex-wrap list-features mt-3">
                    <li>
                      <img
                        alt="bed"
                        src="/img/003-bed.svg"
                        width="22"
                        className="mr-1"
                      />
                      {node.BedroomsTotal}
                    </li>
                    <li>
                      <img
                        alt="shower"
                        src="/img/001-shower.svg"
                        width="22"
                        className="mr-1"
                      />
                      {node.BathroomsTotalInteger}&nbsp;baths
                    </li>
                    <li>
                      <img
                        alt="area"
                        src="/img/004-real-estate.svg"
                        width="22"
                        className="mr-1"
                      />
                      {node.LotSizeSquareFeet}&nbsp;sqft
                    </li>
                  </ul>
                  <h5 className="text-danger card-subtitle my-2">{`$${(node &&
                    node.ListPrice &&
                    node.ListPrice.toString().replace(
                      /\B(?=(\d{3})+(?!\d))/g,
                      ','
                    )) ||
                    'No Price'}`}</h5>
                  {/* <p>{node.StandardStatus}</p> */}
                  {node.VirtualTour ? (
                    <p className="mb-0 mt-3">
                      <a
                        rel="noopener noreferrer"
                        title="Virtual Tour"
                        href={node.VirtualTour}
                        target="_blank"
                      >
                        <i className="fa fa-camera mr-1" />
                        Virtual Tour
                      </a>
                    </p>
                  ) : null}
                </div>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  )
}
// const CustomHits = connectHits(Hits)
// console.log(CustomHits)
// export default CustomHits
export default Hits
