import React from 'react'
import { Link } from 'gatsby'
var slugify = require('slugify')

const SoldListings = ({ soldListing }) => {
  // console.log(soldListing)
  return (
    <div className="container">
      <h2 className="text-center py-5">SOLD PROPERTIES</h2>
      <div className="row align-items-stretch my-5 justify-content-center">
        <p className="text-muted">
          {soldListing.length === 0 ? '- No Record as of Now -' : null}
        </p>
        {soldListing.map(({ node }) => {
          return (
            <div className="col-md-6 col-lg-4 col-xl-3 mb-4" key={node.id}>
              <div className="card bg-white">
                <div className="card-img">
                  <div
                    className="bg-card"
                    style={{
                      backgroundImage: `url(${node.Media[0].asset.url})`,
                      backgroundPosition: 'center',
                      backgroundSize: 'cover',
                      backgroundRepeat: 'no-repeat',
                    }}
                  />
                  <Link
                    to={`/${slugify(node.UnparsedAddress)}`}
                    className="btn-mgnfy"
                  >
                    <i className="fa fa-search" aria-hidden="true" />
                  </Link>
                </div>
                <div className="card-body">
                  <Link
                    to={`/${slugify(node.UnparsedAddress)}`}
                    className="btn-mgnfy"
                  >
                    <h4 className="card-title font-weight-normal">
                      {node.UnparsedAddress}
                    </h4>
                  </Link>
                  <ul className="list-unstyled d-flex justify-content-start align-items-center flex-wrap list-features mt-3">
                    <li>
                      <img
                        alt="bed"
                        src="/img/003-bed.svg"
                        width="22"
                        className="mr-1"
                      />
                      {node.BedroomsTotal}
                    </li>
                    <li>
                      <img
                        alt="shower"
                        src="/img/001-shower.svg"
                        width="22"
                        className="mr-1"
                      />
                      {node.BathroomsTotalInteger}&nbsp;baths
                    </li>
                    <li>
                      <img
                        alt="area"
                        src="/img/004-real-estate.svg"
                        width="22"
                        className="mr-1"
                      />
                      {node.LotSizeSquareFeet}&nbsp;sqft
                    </li>
                  </ul>
                  <h5 className="text-danger card-subtitle my-2">{`$${(node.ListPrice &&
                    node.ListPrice.toString().replace(
                      /\B(?=(\d{3})+(?!\d))/g,
                      ','
                    )) ||
                    'no listed price'}`}</h5>
                  {node.VirtualTour ? (
                    <p className="mb-0 mt-3">
                      <a
                        rel="noopener noreferrer"
                        title="Virtual Tour"
                        target="_blank"
                        href={node.VirtualTour}
                      >
                        <i className="fa fa-camera mr-1" />
                        Virtual Tour
                      </a>
                    </p>
                  ) : null}
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}
export default SoldListings
