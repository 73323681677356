import React from 'react'
import Layout from '../layouts'
import { graphql } from 'gatsby'
import Hits from '../components/search/instantsearch-hits'
// import Searchbar from '../components/search/instantsearch-searchbar'
// import qs from 'qs'
// import AlgoliaLogo from '../images/algolia_logo.png'
import SoldListings from '../components/soldListings'
//import { Link } from 'gatsby'
// import { navigate } from 'gatsby'

class PropertyListings extends React.Component {
  // constructor(props) {
  // 	super(props)
  // 	this.state = {
  // 		menu: null,
  // 		initialQueryParams: null,
  // 		hasStateChanged: false,
  // 		range: null,
  // 	}
  // }
  // componentDidMount() {
  // 	let queryParams
  // 	try {
  // 		queryParams = qs.parse(window.location.search.replace('?', ''))
  // 	} catch (err) {
  // 		// empty query params
  // 	}
  // 	// this.setState({ initialQueryParams: queryParams })
  // }

  // onSearchStateChange = searchState => {
  // 	const updateAfter = 700
  // 	const queryParams = qs.stringify([searchState.menu, searchState.range])

  // 	clearTimeout(this.debouncedSetState)
  // 	this.debouncedSetState = setTimeout(() => {
  // 		this.setState({
  // 			menu: searchState && searchState.menu,
  // 			hasStateChanged: true,
  // 			range: searchState && searchState.range,
  // 			initialQueryParams: queryParams,
  // 		})
  // 		// navigate(`/?${queryParams}`, {})
  // 	}, updateAfter)
  // }

  render() {
    // const location = this.state.initialQueryParams
    // console.log(location)
    // const params = qs.parse(this.props.location.search.replace('?', ''))
    // console.log(params)
    const PListing = this.props.data.allSanityListing.edges
    const soldListing = PListing.filter(
      item =>
        item.node.StandardStatus === 'Sold' ||
        item.node.StandardStatus === 'sold'
    )
    const availableListing = PListing.filter(
      item =>
        item.node.StandardStatus !== 'Sold' &&
        item.node.StandardStatus !== 'sold'
    )
    console.log(PListing.length)
    console.log(availableListing.length)
    console.log(soldListing.length)
    return (
      <Layout
        head={{ title: 'Our Featured Listings' }}
        pageTitle="Our Featured Listings"
      >
        {/*onSearchStateChange={this.onSearchStateChange}*/}
        <div className="page-header page-listing">
          <div
            className="page-banner"
            style={{
              background: `url("https://res.cloudinary.com/brian-boals/image/upload/v1555385799/brianboals/slide12.jpg")`,
            }}
          />
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-xl-11 mx-auto">
                <div className="hero-content-listing text-center">
                  <h1 className="text-white">Our Featured Listings</h1>

                  {/* <div className="d-flex justify-content-center mt-5">
										<a href="https://brianboals.idxbroker.com/idx/results/listings"
											 className="alink hvr-shadow"
											 role="button"
										>
											Search All Properties
										</a>
									</div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <main className="bg-white">
          <section id="featured-listings" className="pt-5 bg-white">
            <div className="container">
              <div className="intro text-center mb-5" />
              <div className="row align-items-stretch">
                <Hits hits={availableListing} hitsClass="ais-Hits-list" />
                {/* <div className="d-flex justify-content-center col-12">
                  <a
                    href="https://brianboals.idxbroker.com/idx/results/listings"
                    className="alink hvr-shadow justify-content-center mb-5"
                    role="button"
                  >
                    MORE LISTING
                  </a>
                </div> */}

                {/* <img
                  src={AlgoliaLogo}
                  alt="algolia logo"
                  className="img-fluid algolia-logo"
                /> */}
              </div>
            </div>
            <SoldListings soldListing={soldListing} />
          </section>
        </main>
      </Layout>
    )
  }
}

export default PropertyListings
export const QueryListing = graphql`
  query {
    allSanityListing(sort: { fields: _createdAt, order: DESC }) {
      edges {
        node {
          id
          ListPrice
          PropertyType
          BedroomsTotal
          BathroomsTotalInteger
          LotSizeSquareFeet
          UnparsedAddress
          City
          StateOrProvince
          PostalCode
          Media {
            asset {
              url
            }
          }
          VirtualTour
          PostalCode
          SchoolDistrictFirst
          SchoolDistrictSecond
          SubdivisionName
          StandardStatus
        }
      }
    }
  }
`
